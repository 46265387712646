import React from 'react';
import { Carousel } from 'react-bootstrap';
import ExampleCarouselImage from './Karuselimage';
import './MyCarousel.css'; // Подключаем файл со стилями

const MyCarousel = () => {
  return (
    <div className="carousel-container">
      <span className="text-black" klm="hhh">сайт как деманстрационный...для просмотра сериала "Season1" и "Season2",по вопросам сотрудничества прокрути вниз</span>
      <Carousel>
        <Carousel.Item>
        <ExampleCarouselImage text="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <ExampleCarouselImage text="Second slide" />
          {/* <div className="overlay-text">This is the second slide</div> */}
        </Carousel.Item>
        <Carousel.Item>
          <ExampleCarouselImage text="Third slide" />
          {/* <div className="overlay-text">This is the third slide</div> */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default MyCarousel;
