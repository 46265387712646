import React from 'react';
import { useStars } from './StarContext';
import {Button,Col,Card,Row} from 'react-bootstrap';

function Season1() {
  const { sez3 } = useStars();

  return (
    <>
  <Row>
      {sez3 && sez3.season1 && sez3.season1.map((star, index) => (
          <Col xs={12} sm={12} md={4} key={index} className="mb-4">
              <Card >
                  <Card.Img variant="top" src={`https://www.eiforiya.site:3001/image/${star.image}`} alt={`Seria ${star.episode}`} />
                  <Card.Body>
                      <Card.Title>Card Title</Card.Title>
                      <Card.Text>
                          Some.. quick example text to build on the card title and make up the
                          bulk of the card's content.
                      </Card.Text>
                      <Button variant="primary" href={`/season_1/${star.episode}`}>Seria: {`${star.episode}`}</Button>
                  </Card.Body>
              </Card>
          </Col>
      ))}
  </Row>
</>
  );
}

export { Season1 };

