import React,{ useState, useEffect } from 'react';
import {Outlet,useLocation } from 'react-router-dom';
import { Container,Row,Col,Button} from 'react-bootstrap';
//import Abrhead from '../pages/header/Abrhead';
import NavigationBar from '../pages/header/NavigationBar';
import Reklama from '../pages/homepage/Reklama';
import MyCarousel from './karusel/MyCarousel';
import sit from './Sit.jpg';

function Layout() {
  const [showNavbar, setShowNavbar] = useState(true); // состояние для управления Navbar
  const location = useLocation();
    // Логика для скрытия/показа Navbar при скролле
  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Скролл вниз
        setShowNavbar(false);
      } else {
        // Скролл вверх
        setShowNavbar(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const bgStyle = {
    backgroundImage: `url(${sit})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    color: 'white',
    "padding-left": 0,
    "padding-right": 0,
    paddingTop: '5rem' // отступ для навбара
  };

  const navbarStyle = {
    transition: 'opacity 0.5s ease, transform 0.8s ease', // плавность перехода
    transform: showNavbar ? 'translateY(0)' : 'translateY(-100%)', // перемещение по оси Y
    opacity: showNavbar ? 1 : 0 // прозрачность
  };

  // Условие для скрытия правого блока для '/season_1' и '/season_2'
  //const hideRightBlock = location.pathname === '/season_1' || location.pathname === '/season_2';
  const hideRightBlock = location.pathname === '/season_1' || location.pathname === '/season_2' || 
    location.pathname.match(/^\/season_1\/\d+$/) || location.pathname.match(/^\/season_2\/\d+$/);
  return (
    <>
    {/* Плавное появление/исчезновение Navbar */}
      <Container fluid className="fixed-top px-0" style={navbarStyle}>
        <NavigationBar /> {/* Добавьте Navbar сюда */}
      </Container>
      {/* Основной контент с фоновым изображением */}
      <Container fluid style={bgStyle}>
          {!hideRightBlock && (
          <>
           <MyCarousel/>
          </>
          )}
       <Row>
         <Col xs={12} md={hideRightBlock ? 12 : 8}>
          <main>
            <Outlet/>
          </main>
         </Col>
         {!hideRightBlock && (
         <Col xs={6} md={4}>{/* Правый блог*/}
           <Button onClick={() => alert('Button Clicked!')}>Click Mess</Button>
           <Reklama/> 
            {/* Другие интерактивные элементы */}
         </Col>
         )}
       </Row>
      </Container>
      <footer className="bg-dark text-white mt-5 p-4 text-center">
          <h5>
                 Предлагаю!
   Реализацию вашего проекта с использованием современных подходов, современных и актуальных на сегодняшний день языков.
   Использование данных, через mysql или json.Получение сторонних данных с помощью API c дальнейшей обработкой их.
   React, Nodejs и другие фреймворки. Установка проекта на выделенном сервере, настройка nginx для ускорения работы приложения с последующей поддержкой.
   По вопросам сотрудничества писать на sletozima@gmail.com (Сергей +79292211505)
          </h5>
      </footer>  
    </>
  )
}

export default Layout
