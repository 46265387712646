import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loggo from './Loggo.png'; 

function NavigationBar() {
  const logoUrl ='./Res.svg';
  return (
    <Navbar bg="dark" data-bs-theme="dark" expand="lg">
        <Navbar.Brand href="/">
          <img
            src={loggo}
            width="100"
            height="30"
            className="d-inline-block align-top ps-2"
            alt="Loggo"
          />
        </Navbar.Brand>
      <div className='pe-3'>
       <Navbar.Toggle aria-controls="basic-navbar-nav" />
      </div>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/season_1">Season 1</Nav.Link>
          <Nav.Link as={Link} to="/season_2">Season 2</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavigationBar;



